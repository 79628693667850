<template>
    <CModal 
        title="Download Report" 
        :show.sync="showModalDownloadFilter_" 
        color="info" 
        size="md" 
        @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">
                <h5>Choose a filter to download a report</h5><br>
				<CRow class="mb-2">
                    <CCol lg="12" class="">
						<label class="mb-1">DR ID</label>
						<CInput v-model="filter.dr_id"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="">Customer</label>
                        <v-select label="customer_name" :options="customer_list.data" :reduce="item => item.id" v-model="filter.customer_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="">
						<label class="mb-1">Asset No.</label>
                        <CInput v-model="filter.asset_no"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="">Delivery Date From</label>
                        <Datepicker v-model="filter.delivery_date_from" input-class="form-control"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
                        <label class="mb-1">Delivery Date To</label>
                        <Datepicker v-model="filter.delivery_date_to" input-class="form-control"/>
                    </CCol>
                    <CCol lg="12" class="mb-3">
                        <h6 class="text-danger" v-if="no_filter">{{error_msg}}</h6>
                    </CCol>
				</CRow>
            </div>
        </form>
        <div slot="footer" class="w-100" >
            <CButton class="float-right" size="sm" color="success" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../config.js';
import axios from '../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';  
export default {
    components: { vSelect, Datepicker },
    mounted() {
        
    },
    props: {
        showModalDownloadFilter: {required: true},
        filters: {}
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
                dr_id: "",
	            delivery_date_from: moment(new Date()).subtract(6, 'month')._d,
	            delivery_date_to: new Date(),
	            booking_id: "",
	            customer_id: "",
	            checked_by: "",
	            released_by: "",
	            received_by: "",
	            delivered_by: "",
				asset_no : ""
			},
            customer_list : [],
            no_filter:false,
            currentPage:1,
            processing:false,
            ready_download:false,
            link_download:'',
            error_msg:'',
            report_downloaded: false
        }
    },
    methods: {
        async download() {
            this.report_downloaded = false;
            if(this.dataValidate() && this.dateFilterValidatePrompt()){
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;
               
                var delivery_date_from = this.filter.delivery_date_from;
                if(delivery_date_from != ""){
                    delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }

                var delivery_date_to = this.filter.delivery_date_to;
                if(delivery_date_to != ""){
                    delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }

                // const response = await axios.get(config.api_path+'/reports/deliveryReceipt?'+
                // '&company_id='+config.global_user_data.company_id+
                // '&dr_id='+this.filter.dr_id+
                // '&customer_id='+this.filter.customer_id+
                // '&asset_no='+this.filter.asset_no+
                // '&delivery_date_to='+delivery_date_to+
                // '&delivery_date_from='+delivery_date_from+
                // '&limit=20')
                await axios.post(`${config.api_path}/reports/deliveryReceipt`, {
                    company_id:config.global_user_data.company_id,
                    dr_id:this.filter.dr_id,
                    customer_id:this.filter.customer_id,
                    asset_no:this.filter.asset_no,
                    status:this.filter.status,
                    delivery_date_to:delivery_date_to,
                    delivery_date_from:delivery_date_from,
                
                })
                .then(response=>{
                    this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                    this.watchDownload(response.data.file);
                    this.no_filter = false;
                    this.error_msg = '';
                })
                .catch(err => {
                    this.no_filter = true;
                    this.ready_download = false;
                    this.processing = false;
                });
            }
            else{
                this.no_filter = true;
                this.ready_download = false;
                this.processing = false;
                this.error_msg = 'Please select a filter before downloading the report.';
                this.dateFilterValidatePrompt();
            }
        },
        getCustomer() {
            axios.get(config.api_path + '/reference/customer-list', {
                params: {show_all: true}
            }).then(response => {
                this.customer_list = response.data;
            });
        },
        clear(){
            this.filter.dr_id = '';
            this.filter.customer_id = '';
            this.filter.asset_no = '';
            this.filter.delivery_date_from = moment(new Date()).subtract(5, 'month')._d;
            this.filter.delivery_date_to = new Date();
        },
        dataValidate(){
            return true;
            if(
                this.filter.dr_id || 
                this.filter.customer_id || 
                this.filter.asset_no ||
                (this.filter.delivery_date_from && this.filter.delivery_date_to && this.dateRangeValidate(this.filter.delivery_date_from, this.filter.delivery_date_to)) 
            ){
                return true;
            }
            else{
                return false;
            }
        },
        dateRangeValidate(f, t){
            let from = f;
            let to = t;
            if(!from&&!to){
                return true;
            }else{
                if(from && to){
                    const start = new Date(from);
                    const end = new Date(to);
                    const differenceInMilliseconds = end - start;
                    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                    return differenceInDays <= 6;
                }
                else{
                    return false;
                }
            }
        },
        // 'Filter date should be 6 months only.';
        dateFilterValidatePrompt(){
            let from = this.filter.delivery_date_from;
            let to = this.filter.delivery_date_to;
            let num_month = moment(to).diff(from, 'months');
           
            if(from && to){
                // const start = new Date(from);
                // const end = new Date(to);
                // const differenceInMilliseconds = end - start;
                // const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                // console.log(differenceInDays)
                // if(!(differenceInDays <= 6)){
                //     this.error_msg = 'Filter date should be 6 months only.';;
                //     return false;
                // }
                // else{
                //     return true;
                // }
                if(num_month > 6) {
                    this.error_msg = 'Filter date should be 6 months only.';;
                    return false;
                }

                return true;
            }
            else{
                return true
            }
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        },
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;

            if(this.processing || this.ready_download)
                    return;

            if(!this.customer_list?.data?.length)
                this.getCustomer();

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        }
    },
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}
</style>