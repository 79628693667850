<template>
	<div>
		
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Delivery Receipt Report {{ total ? `(${total})` : ''}}</h5>
			</CCol>
	     		 <CCol lg="6">
		      		 <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <!-- <CButton 
							v-if="config.getPermission('dr_reports').download"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							@click="download"
							class="float-lg-right">
							<i class="fa fa-download">
							</i>Download
					</CButton> -->
					<CButton 
							v-if="config.getPermission('dr_reports').download"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							class="float-lg-right"
							@click="() => { showModalDownloadFilter = true }">
							<i class="fa fa-download"></i> Download
					</CButton>
			       
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	      	 
		      <CCol lg="12">  
				<div class="table-responsive-sm table-responsive-md table-responsive-lg">
	      		<CDataTable
				  :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined
					
					
			    >  
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>
					<!-- <template #assettype="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.delivery_receipt_item">
    							•&nbsp;&nbsp;{{row.asset_type}}
    							
    						</div>
 						</td>
			      	</template>
			      	<template #assetno="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.delivery_receipt_item">
    							•&nbsp;&nbsp;{{row.no}}
    							
    						</div>
 						</td>
			      	</template>
			      	<template #qty="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.delivery_receipt_item">
    							•&nbsp;&nbsp;{{row.qty}}
    							
    						</div>
 						</td>
			      	</template>
					<template #particular="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.delivery_receipt_item">
    							•&nbsp;&nbsp;{{row.particular  != null && row.particular  != "" ? row.particular  : "N/A"}}
    							
    						</div>
 						</td>
			      	</template>
			      	<template #brand="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.delivery_receipt_item">
    							•&nbsp;&nbsp;{{row.brand != null && row.brand != "" ? row.brand : "N/A"}}
    							
    						</div>
 						</td>
			      	</template>
			      	<template #serialno="{item}">
						<td style="padding: 5px; width: 100px; ">
    						<div v-for="(row, index) in item.delivery_receipt_item">
    							•&nbsp;&nbsp;{{row.serial_no != null && row.serial_no != "" ? row.serial_no : "N/A"}}
    							
    						</div>
 						</td>
			      	</template> -->

			    	<!-- <template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem @click="viewModal(item)">View</CDropdownItem>  
				            	<CDropdownItem @click="updateModal(item)">Update</CDropdownItem>  
								<CDropdownItem @click="opentransferModal(item)">Transfer Equipment</CDropdownItem></CDropdownItem>  
								<CDropdownItem @click="openhistoryModal(item)">Equipment History</CDropdownItem>  
				            	<CDropdownItem @click="deleteItem(item)">Delete</CDropdownItem>   
								
				            </CDropdown>
				        </td>
			      	</template>  -->
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        /> 
			</div>
	      	</CCol>
	    </CRow> 
		  <!-- <HistoryModal :details="selectedRow" @showModal="historyModal = $event; reloadData();" :showModal="historyModal" /> -->
		   <!-- <TransferModal :details="selectedRow" @showModal="transferModal = $event; reloadData();" :showModal="transferModal" /> -->
		</CCard>
		<download-filter :filters="filter" :showModalDownloadFilter="showModalDownloadFilter" @closeModalDownloadFilter="closeModalDownloadFilter"/>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './dr_search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import downloadFilter from './dr_report_filter.vue';
import '../style.css';


export default {
	mounted(){  
		// this.getVendor();
		// this.getCustomer();
		this.getData();  
	},
	data(){
		return{
			config,
			isLoading: false,
			// historyModal: false,
			// transferModal: false,
			// dispatchModal: false,
			// deliverModal: false,
			// completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [],  
			depotName: "", 
			total : 0,
			fields: [
				{
					key: 'dr_id', 
					label: 'DR ID'
				},
				{
					key: 'booking_no', 
					label: 'Booking No.'
				},
				{
					key: 'delivery_date', 
					label: 'Delivery Date'
				},
				
				{
					key: 'customer_name', 
					label: 'Customer'
				}, 
				// {
				// 	key: 'address', 
				// 	label: 'Address'
				// },
				{
					key: 'assettype', 
					label: 'Asset Type'
				},
				{
					key: 'assetno', 
					label: 'Asset No'
				},
				{
					key: 'qty', 
					label: 'Qty'
				},  
				{
					key: 'particular', 
					label: 'Particular'
				},
				{
					key: 'brand', 
					label: 'Brand'
				},
				{
					key: 'serialno', 
					label: 'Serial No'
				},
				// {
				// 	key: 'ref_no', 
				// 	label: 'Ref No.'
				// },
				// {
				// 	key: 'remarks', 
				// 	label: 'Remarks',
				// }, 
				
				// {
				// 	key: 'released_by', 
				// 	label: 'Released By'
				// },
				// {
				// 	key: 'checked_by', 
				// 	label: 'Checked By'
				// },
				// {
				// 	key: 'received_by', 
				// 	label: 'Received By'
				// },
				// {
				// 	key: 'delivered_by', 
				// 	label: 'Delivered By'
				// },
				// {
				// 	key: 'action', 
				// 	label: 'Action'
				// }
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				dr_id: "",
	            delivery_date_from: moment(new Date()).subtract(6, 'month')._d,
	            delivery_date_to: new Date(),
	            booking_id: "",
	            customer_id: "",
	            checked_by: "",
	            released_by: "",
	            received_by: "",
	            delivered_by: "",
				asset_no : ""
			},
			// vendorList: {
			// 	data: []
			// },
			// customerList: {
			// 	data:[]
			// }
			showModalDownloadFilter:false,
		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect, downloadFilter},
	methods: {
		 getBadge (status) {
    	return status === 'operational' ? 'success'
       		 : status === 'breakdown' ? 'dark'
         	 : status === 'for scrap' ? 'warning'
			 : status === 'standby' ? 'secondary'
			 : status === 'for erection' ? 'primary'
         	 : status === 'for inspection' ? 'info'
			 : status === 'dismantle' ? 'light'
			 :status === 'for rehab' ? 'success'
			 :status === 'pull-out' ? 'success'
             : status === 'reserve' ? 'danger' : 'primary'
    },
		reloadData(){
			this.getData();  
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},   

		// updatePageUnderReview(data){
		// 	this.currentPageUnderReview = data;
		// 	this.getDataUnderReview();
		// },   

		// updatePageApproved(data){
		// 	this.currentPageApproved = data;
		// 	this.getDataApproved();
		// },   

		// updatePageDeclined(data){
		// 	this.currentPageDeclined = data;
		// 	this.getDataDeclined();
		// },   

		// updatePageDispatched(data){
		// 	this.currentPageDispatched = data;
		// 	this.getDataDispatched();
		// },   

		// updatePageDelivered(data){
		// 	this.currentPageDelivered = data;
		// 	this.getDataDelivered();
		// },   

		// updatePageCompleted(data){
		// 	this.currentPageCompleted = data;
		// 	this.getDataCompleted();
		// },   

		// updatePageCancelled(data){
		// 	this.currentPageCancelled = data;
		// 	this.getDataCancelled();
		// },   

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 
		openhistoryModal(item){
	    	this.historyModal = true;
	    	this.selectedRow = item;
	    },
 		 opentransferModal(item){
	    	this.transferModal = true;
	    	this.selectedRow = item;
	    },
	    getData(){   
			this.isLoading=true;

			console.log(this.filter)
			var delivery_date_from = this.filter.delivery_date_from;
			if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
			if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var booking_id = this.filter.booking_id;
	    	if(booking_id == "all" || booking_id == null){
	    		booking_id = ""
	    	}
	    	var delivered_by = this.filter.delivered_by;
	    	if(delivered_by == "all" || delivered_by == null){
	    		delivered_by = ""
	    	}  
	    	axios.get(config.api_path+'/deliveryReceipt?dr_id='+this.filter.dr_id+'&delivery_date_from='+delivery_date_from+'&delivery_date_to='+delivery_date_to+'&booking_id='+booking_id+'&delivered_by='+delivered_by+'&customer_id='+customer_id+'&checked_by='+this.filter.checked_by+'&released_by='+this.filter.released_by+'&received_by='+this.filter.received_by+'&page='+this.currentPage+'&limit=20&asset_no='+this.filter.asset_no)
	    	.then(response => {
				console.log('AAAAAAAA')
	    		this.dataList = response.data; 
				this.total = response.data.total;
	    		this.dataList.data = this.dataList.data.map((value, index)=>{ 
	    			// value.booking_no = value.booking.booking_no;
	    			value.customer_name = value.customer_name;
	    			value.remarks = value.remarks != "" ? value.remarks : "N/A";
	    			value.released_by = value.released_by != "" ? value.released_by : "N/A";
	    			value.received_by = value.received_by != "" ? value.received_by : "N/A";
	    			value.checked_by = value.checked_by != "" ? value.checked_by : "N/A";
	    			value.delivered_by = value.delivered ? value.delivered.name : "N/A";
					value.particular = value?.particular ? this.$options.filters.truncate(value.particular):'-';
	    			// value.particular = value.delivery_receipt_item.length > 0 ? value.delivery_receipt_item[0].particular : "N/A";
	    			// value.particular = value.particular ? value.particular : 'N/A';
					value.serialno = value.serialno  ? value.serialno : 'N/A';
					value.brand = value.brand  ? value.brand : 'N/A';
	    			value.delivery_date = value.delivery_date ? moment.unix(value.delivery_date).format('MM/DD/YYYY') : "N/A"; 
					

	    			return value;
	    		}); 
	    	})
				.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },  

	    // getVendor(){

	    // 	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.vendorList = response.data;
	    // 		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			value.label = value.vendor_name;
	    // 			return value;
	    // 		})
	    // 	})

	    // },

	//      getCustomer(){

    //     axios.get(config.api_path+'/reference/customer-list')
    //     .then(response => {
    //       this.customerList = response.data; 
    //       this.customerList.data.unshift({
    //         customer_name: 'All',
    //         id: 'all'
    //       })
    //     })

    //   }, 

	    download(){ 
	    	var delivery_date_from = this.filter.delivery_date_from;
			if(delivery_date_from != ""){
	    		delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var delivery_date_to = this.filter.delivery_date_to;
			if(delivery_date_to != ""){
	    		delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
	    	}

	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	} 
	    	var booking_id = this.filter.booking_id;
	    	if(booking_id == "all" || booking_id == null){
	    		booking_id = ""
	    	}
	    	var delivered_by = this.filter.delivered_by;
	    	if(delivered_by == "all" || delivered_by == null){
	    		delivered_by = ""
	    	}  
			
			this.$showLoading(true)
	    	axios.get(config.api_path+'/reports/deliveryReceipt?dr_id='+this.filter.dr_id+'&delivery_date_from='+delivery_date_from+'&delivery_date_to='+delivery_date_to+'&booking_id='+booking_id+'&delivered_by='+delivered_by+'&customer_id='+customer_id+'&checked_by='+this.filter.checked_by+'&released_by='+this.filter.released_by+'&received_by='+this.filter.received_by+'&page='+this.currentPage+'&limit=20') 
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
				
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    },
		closeModalDownloadFilter(status){
			this.showModalDownloadFilter = status;
		},
 	}
}
</script>./dr_report_filter.vue/index.js
